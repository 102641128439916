import React, { useState, useCallback,useEffect } from "react";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useSendEmailBusinessVerificationMutation } from "../../graphql/generated-types";

const Complete = () => {
  const navigate = useNavigate();
  const { user, isLoaded: isUserLoaded } = useUser();
  const { organization, isLoaded: isOrganizationLoaded } = useOrganization();
  const [isCertifiedOrganic, setIsCertifiedOrganic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [file, setFile] = useState(null);
  const [certificateUploaded, setCertificateUploaded] = useState(false);

  const [sendEmailBusinessVerification] = useSendEmailBusinessVerificationMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      setIsSubmitted(false);
      setCertificateUploaded(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  if (!isOrganizationLoaded || !isUserLoaded) return null;

  const handleAction = (action) => {
    switch (action) {
      case "search":
        navigate("/search");
        break;
      case "create":
        navigate("/marketplace/list/selling");
        break;
      default:
        break;
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    setLoading(true);

    try {
      await sendEmailBusinessVerification({
        variables: {
          clerkUserId: user.id,
          clerkOrganizationId: organization.id,
          image: file,
        },
      });
      setIsSubmitted(true);
      setCertificateUploaded(true);
    } catch (error) {
      console.error("Error uploading certificate:", error);
      alert("An error occurred while uploading your certificate. Please try again.");
    }

    setLoading(false);
  };

  const renderContent = () => {
    if (isCertifiedOrganic === null) {
      return (
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <h2 className="text-2xl font-semibold mb-6 text-center text-primary-900 font-general-sans">Are you Certified Organic?</h2>
          <div className="flex flex-col sm:flex-row gap-4">
            <button
              onClick={() => setIsCertifiedOrganic(true)}
              className="w-full px-6 py-3 text-primary-900 font-semibold bg-[#C5E1A5] hover:bg-[#AED581] rounded-full transition duration-150 ease-in-out"
            >
              Yes, I am Certified Organic
            </button>
            <button
              onClick={() => setIsCertifiedOrganic(false)}
              className="w-full px-6 py-3 text-primary-900 font-semibold bg-[#FFCC80] hover:bg-[#FFB74D] rounded-full transition duration-150 ease-in-out"
            >
              No, I am not Certified Organic
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4 font-general-sans text-primary-900">Next steps:</h2>
        {isCertifiedOrganic ? renderCertifiedContent() : renderNonCertifiedContent()}
        <button
          onClick={() => setIsCertifiedOrganic(null)}
          className="mt-4 text-[#1B5E20] hover:text-[#2E7D32] font-semibold"
        >
          ← Back
        </button>
      </div>
    );
  };

  const renderCertifiedContent = () => {
    return (
      <div className="border rounded-lg p-4">
        <p className="mb-4 text-sm sm:text-base font-semibold text-primary-900">Upload your Organic Certificate to make listings of Organic goods you want to sell:</p>
        {renderUploadSection()}
        {isSubmitted && (
          <div className="p-4 rounded-lg bg-blue-50 border-l-4 border-blue-400 mb-4">
            <div className="flex items-start">
              <svg className="w-6 h-6 text-blue-400 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <p className="text-blue-700 font-medium text-sm sm:text-base">
                Your Organic Certificate is under review! When approved, you will receive an email.
              </p>
            </div>
          </div>
        )}
        <p className="mt-4 mb-4 text-sm sm:text-base font-semibold text-primary-900">
          {isSubmitted 
            ? "While you wait for your Organic Certificate to be approved, you can:" 
            : "Not ready to upload your Organic Certificate? You can still:"}
        </p>
        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mb-4">
          <button 
            className="flex-1 px-4 py-2 text-primary-900 font-semibold bg-[#C5E1A5] hover:bg-[#AED581] rounded-full shadow-sm transition duration-150 ease-in-out text-sm sm:text-base"
            onClick={() => handleAction('search')}
          >
            Search Market.Organic
          </button>
          <button 
            className="flex-1 px-4 py-2 text-primary-900 font-semibold bg-[#FFCC80] hover:bg-[#FFB74D] rounded-full shadow-sm transition duration-150 ease-in-out text-sm sm:text-base"
            onClick={() => handleAction('create')}
          >
            Create Buying Listings
          </button>
        </div>
      </div>
    );
  };

  const renderUploadSection = () => (
    <div className="mb-4">
      <div
        {...getRootProps({
          className: "dropzone p-4 sm:p-5 w-full flex flex-col gap-2 items-center justify-center text-center border-2 border-dashed border-[#1B5E20] rounded-md hover:bg-[#E8F5E9] cursor-pointer",
        })}
      >
        <input {...getInputProps()} />
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-[#1B5E20] block mx-auto w-8 h-8 sm:w-12 sm:h-12"
        >
          <path
            d="M28 8H12C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12V32M8 32V36C8 37.0609 8.42143 38.0783 9.17157 38.8284C9.92172 39.5786 10.9391 40 12 40H36C37.0609 40 38.0783 39.5786 38.8284 38.8284C39.5786 38.0783 40 37.0609 40 36V28M8 32L17.172 22.828C17.9221 22.0781 18.9393 21.6569 20 21.6569C21.0607 21.6569 22.0779 22.0781 22.828 22.828L28 28M40 20V28M40 28L36.828 24.828C36.0779 24.0781 35.0607 23.6569 34 23.6569C32.9393 23.6569 31.9221 24.0781 31.172 24.828L28 28M28 28L32 32M36 8H44M40 4V12M28 16H28.02"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="font-medium text-xs sm:text-sm text-[#1B5E20]">
          Upload a file or drag and drop
        </div>
        <div className="text-xs text-[#2E7D32]">Up to 10MB</div>
      </div>
      {file && (
        <div className="mt-4">
          <h4 className="font-medium text-sm sm:text-base text-primary-900">Selected file:</h4>
          <p className="text-xs sm:text-sm text-primary-900">{file.name}</p>
          {!certificateUploaded && (
            <button 
              className="w-full mt-4 px-4 py-2 text-white font-semibold bg-[#1B5E20] hover:bg-[#2E7D32] rounded-full shadow-sm transition duration-150 ease-in-out text-sm sm:text-base"
              onClick={handleUpload}
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload Organic Certificate"}
            </button>
          )}
        </div>
      )}
    </div>
  );

  const renderNonCertifiedContent = () => {
    return (
      <div className="border rounded-lg p-4">
        <p className="mb-4 text-sm sm:text-base text-primary-900">You can now search Market.Organic for Certified Organic goods and create listings for Certified Organic goods you want to buy.</p>
        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
          <button 
            className="flex-1 px-4 py-2 text-primary-900 font-semibold bg-[#C5E1A5] hover:bg-[#AED581] rounded-full shadow-sm transition duration-150 ease-in-out text-sm sm:text-base"
            onClick={() => handleAction('search')}
          >
            Search Market.Organic
          </button>
          <button 
            className="flex-1 px-4 py-2 text-primary-900 font-semibold bg-[#FFCC80] hover:bg-[#FFB74D] rounded-full shadow-sm transition duration-150 ease-in-out text-sm sm:text-base"
            onClick={() => handleAction('create')}
          >
            Create Buying Listings
          </button>
        </div>
      </div>
    );
  };

  return (
    <main className="container mx-auto px-4 py-6 sm:py-8 font-manrope text-primary-900">
      <section className="container--small mx-auto max-w-2xl">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6 font-general-sans text-[#0D5A1B]">Welcome to Market.Organic!</h1>

        <div className="bg-white shadow-md rounded-lg p-4 sm:p-6 mb-6 sm:mb-8">
          {renderContent()}
        </div>

        <div className="bg-[#E8F5E9] rounded-lg p-4 sm:p-6">
          <h2 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4 font-general-sans text-primary-900">Your Account Information</h2>
          <div className="flex items-center gap-3 sm:gap-4 mb-3 sm:mb-4">
            <div className="w-8 h-8 sm:w-10 sm:h-10 bg-[#C5E1A5] flex items-center justify-center rounded-full flex-shrink-0">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="sm:w-6 sm:h-6">
                <path d="M19 21V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V21M19 21L21 21M19 21H14M5 21L3 21M5 21H10M9 6.99998H10M9 11H10M14 6.99998H15M14 11H15M10 21V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V21M10 21H14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div>
              <p className="font-semibold text-sm sm:text-base text-primary-900">{organization.name}</p>
              <p className="text-xs sm:text-sm text-gray-600">[State/province, Country]</p>
            </div>
          </div>
          <div className="flex items-center gap-3 sm:gap-4">
            <div className="w-8 h-8 sm:w-10 sm:h-10 bg-[#C5E1A5] flex items-center justify-center rounded-full flex-shrink-0">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="sm:w-6 sm:h-6">
                <path d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <p className="text-sm sm:text-base break-all text-primary-900">{user.primaryEmailAddress.emailAddress}</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Complete;