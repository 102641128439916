import React, { useRef, useState } from "react";
import InputField, { InputLabel } from "../marketplace/InputField";
import { businessTypesData, countries } from "../../data";
import { useOrganization } from "@clerk/clerk-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { notifyUpdated } from "../Utils/notifyUpdated";
import { DefaultAvatar } from "./UserButton";
import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useGetMeQuery } from "../../graphql/generated-types";

const CompanyInformation = () => {
  const { loading, error, data: meData } = useGetMeQuery();

  const operationProducts =
    meData?.me?.organizations?.[0]?.operations?.[0]?.products || [];

  return (
    <>
      <div className="mb-4 flex flex-col">
        <h1 className="text-lg font-medium text-gray-900">
          Organization Information
        </h1>
        <p className="text-sm text-gray-500">
          This information will be displayed publicly so please be mindful of
          what you share.
        </p>
      </div>
      <div>
        <div className="flex flex-col">
          <CompanyAddressForm />
          <hr className="my-10" />
          <CompanyMoreDetailsForm />
          <hr className="my-10" />
          <CompanyProductListForm
            products={operationProducts.map((product) => product.name)}
          />
        </div>
      </div>
    </>
  );
};

type CompanyAddressInputs = {
  name: string;
  orgLogo?: File;
  country: string;
  city: string;
  state: string;
  postalCode: string;
  website: string;
};

const CompanyAddressForm = () => {
  const { organization } = useOrganization();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyAddressInputs>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const onSubmit: SubmitHandler<CompanyAddressInputs> = async ({
    name,
    orgLogo,
  }) => {
    setLoading(true);

    try {
      const result = await organization.update({ name });

      if (orgLogo) {
        try {
          const resultLogo = await organization.setLogo({ file: orgLogo[0] });
        } catch (error) {
          console.warn(error);
        }
      }

      notifyUpdated();
    } catch (error) {}

    setLoading(false);
  };

  if (!organization) return;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames({ "pointer-events-none opacity-50": loading })}
    >
      <div className="flex flex-col gap-6">
        <div>
          <InputLabel text="Company Logo" />
          <div className="flex gap-4 items-center">
            <DefaultAvatar defaultValue={imagePreview} type="organization" />

            <input
              type="file"
              {...register("orgLogo", {
                onChange: handleFileChange,
              })}
              ref={(e) => {
                register("orgLogo").ref(e);
                fileInputRef.current = e;
              }}
              className="hidden"
            />

            <div>
              <button
                type="button"
                className="text-sm text-primary-700 px-2.5 py-1.5 rounded-full border border-primary-300 shadow-sm font-medium hover:bg-gray-50"
                onClick={() => fileInputRef?.current?.click()}
              >
                Change
              </button>
            </div>
          </div>
        </div>
        <InputField
          label="Organization or farm name"
          defaultValue={organization.name}
          register={register("name", {
            required: true,
          })}
          error={errors.name?.message}
        />
        <InputField
          label="Country"
          required
          type="select"
          selectOptions={countries}
          register={register("country")}
        />
        <div className="grid grid-cols-3 gap-6">
          <InputField label="City" register={register("city")} />
          <InputField label="State / Province" register={register("state")} />
          <InputField label="ZIP / Postal" register={register("postalCode")} />
        </div>
        <InputField label="Website (optional)" register={register("website")} />
        <div className="flex justify-end mt-2 gap-6">
          <button
            type="submit"
            disabled={loading}
            className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

type CompanyMoreDetailsInputs = {
  businessType: string;
};

const CompanyMoreDetailsForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyMoreDetailsInputs>();

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<CompanyMoreDetailsInputs> = async ({
    businessType,
  }) => {
    setLoading(true);

    try {
      notifyUpdated();
    } catch (error) {}

    setLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames({ "pointer-events-none opacity-50": loading })}
    >
      <div className="mb-6">
        <h2 className="text-gray-900 font-medium">
          Business type (check all that apply)
        </h2>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2.5">
          {businessTypesData.map((option, index) => (
            <label
              key={`interest-${index}`}
              className="text-sm font-medium text-gray-900"
            >
              <input
                type="checkbox"
                name="businessType"
                value={`type-${index}`}
                className="mr-2"
                {...register("businessType")}
              />

              {option}
            </label>
          ))}
        </div>

        <div className="flex justify-end mt-2 gap-6">
          <button
            type="submit"
            disabled={loading}
            className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

type CompanyProductListInputs = {
  products: string[];
};

const CompanyProductListForm = ({ products }: CompanyProductListInputs) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CompanyProductListInputs>();

  const [loading, setLoading] = useState(false);
  const [newProducts, setNewProducts] = useState([]);

  const onSubmit: SubmitHandler<CompanyProductListInputs> = async (data) => {
    if (!data.products) return;
    setLoading(true);

    try {
      setNewProducts([...newProducts, data.products]);
      reset();
    } catch (error) {}

    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const removeTag = (indexToRemove) => {
    setNewProducts(products.filter((_, index) => index !== indexToRemove));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames({ "pointer-events-none opacity-50": loading })}
    >
      <div className="mb-6">
        <h2 className="text-gray-900 font-medium">Product list</h2>
        <p className="text-sm text-gray-500">
          Keeping your product list up to date makes it easier for other
          businesses to find you.
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <div>
          <div>
            <h3 className="text-sm mb-1">Your products</h3>
            {products.length ? (
              <div className="mb-2">
                <ul className="inline-flex flex-wrap gap-2">
                  {products.map((product, index) => (
                    <li key={`${product}-${index}`}>
                      <span className="bg-primary-50  text-primary-800 font-medium px-3 py-0.5 rounded-full inline-block text-sm">
                        {product}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            {newProducts.length ? (
              <div className="flex gap-2 border-t pt-2">
                {newProducts.map((product, index) => (
                  <div
                    key={index}
                    className="rounded-full bg-gray-100 font-semibold px-2 py-1 text-xs flex items-center gap-1 mb-2"
                    onClick={() => removeTag(index)}
                  >
                    {product}{" "}
                    <span
                      onClick={() => removeTag(index)}
                      className="hover:opacity-70 cursor-pointer"
                    >
                      <XMarkIcon className="size-4" />
                    </span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div>
            <textarea
              {...register("products")}
              placeholder="Enter a product and press 'Enter' to add it to your list"
              onKeyDown={handleKeyDown}
              rows={3}
            />
          </div>
        </div>

        <div className="flex justify-end mt-2 gap-6">
          <button
            type="submit"
            disabled={loading}
            className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export { CompanyInformation };
