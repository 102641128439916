import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import InputField from "../marketplace/InputField";
import { businessTypesData, countries } from "../../data";
import { SubmitHandler, useForm } from "react-hook-form";
import { useOrganizationList, useUser } from "@clerk/clerk-react";

type BusinessProfileUpInputs = {
  isNotBusiness: boolean;
  organizationName: string;
  country: string;
  city: string;
  state: string;
  postalCode: string;
  website: string;
  businessType: string;
  email: string;
  phoneNumber: string;
};

const StepBusinessProfile = () => {
  const {
    isLoaded: isClerkLoaded,
    createOrganization,
    setActive,
    userMemberships,
  } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  const { user } = useUser();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<BusinessProfileUpInputs>();

  const navigate = useNavigate();

  const isNotBusiness = watch("isNotBusiness");

  const [loading, setLoading] = useState(false);
  const [clerkError, setClerkError] = useState("");

  useEffect(() => {
    if (isNotBusiness) {
      navigate("/signup/plans");
    }
  }, [isNotBusiness, navigate]);

  useEffect(() => {
    if (user?.emailAddresses[0]?.emailAddress) {
      setValue("email", user.emailAddresses[0].emailAddress);
    }
  }, [user, setValue]);

  const onSubmit: SubmitHandler<BusinessProfileUpInputs> = async (inputs) => {
    if (inputs.isNotBusiness) {
      navigate("/signup/plans");
      return;
    }

    setLoading(true);
    setClerkError("");

    try {
      const result = await createOrganization({
        name: inputs.organizationName,
      });

      await selectOrganization();
    } catch (err: any) {
      console.warn(JSON.stringify(err, null, 2));
      setClerkError(err.errors[0].message);
    }

    setLoading(false);
  };

  const selectOrganization = async () => {
    try {
      const res = await userMemberships.revalidate();
      const firstOrganization = res?.[0]?.data?.[0];

      if (firstOrganization) {
        setActive(firstOrganization);
        navigate("/signup/plans");
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const businessFieldsClasses = classNames({
    "flex flex-col gap-6": true,
    hidden: isNotBusiness,
  });

  return (
    <>
      <div className="mb-6">
        <h1 className="text-xl md:text-2xl text-gray-900 font-medium">
          Your organization information
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="text-base">
        <div className="flex flex-col gap-6">
          <label className="text-base font-medium text-gray-900">
            <input
              type="checkbox"
              name="not-business-affiliated"
              className="mr-2 h-5 w-5"
              {...register("isNotBusiness")}
            />
            I am not affiliated with a business, farm, or organization
          </label>

          <div className={businessFieldsClasses}>
            <InputField
              label="Business, Farm, or Organization Name"
              name="organizationName"
              required
              register={register("organizationName", {
                required: !isNotBusiness,
              })}
            />
            <InputField
              label="Country"
              required
              type="select"
              selectOptions={countries}
              register={register("country")}
            />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <InputField label="City" register={register("city")} />
              <InputField
                label="State / Province"
                register={register("state")}
              />
              <InputField
                label="ZIP / Postal"
                register={register("postalCode")}
              />
            </div>
            <InputField
              label="Business Email"
              type="email"
              required
              register={register("email", { required: true })}
            />
            <InputField
              label="Business Phone Number (optional)"
              type="input"
              register={register("phoneNumber", {
                pattern: {
                  value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                  message: "Invalid phone number format",
                },
              })}
            />
            <p className="text-base text-gray-600">
              Your contact information can be used to receive messages about your Market.Organic listings.
            </p>
            <InputField
              label="Website (optional)"
              register={register("website")}
            />

            <hr className="border-gray-300" />

            <div>
              <div className="text-base text-gray-700 font-medium mb-2">
                Business type (check all that apply)
              </div>
              <div className="flex flex-col gap-4">
                {businessTypesData.map((option, index) => (
                  <label
                    key={`interest-${index}`}
                    className="flex items-center text-base font-medium text-gray-700 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      name="businessType"
                      value={`type-${index}`}
                      className="mr-3 h-5 w-5"
                      {...register("businessType")}
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="border-t pt-8 mt-8 flex flex-col md:flex-row justify-end items-center gap-4">
          <button
            type="button"
            onClick={() => navigate("/signup/user-profile")}
            className="w-full md:w-auto rounded-full px-6 py-3 text-center shadow-sm hover:bg-gray-50 border border-primary-300 text-gray-700 font-semibold text-lg"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={loading}
            className="w-full md:w-auto rounded-full px-6 py-3 text-center shadow-sm bg-primary-600 hover:bg-primary-700 text-white font-semibold text-lg disabled:bg-gray-400 disabled:pointer-events-none"
          >
            Continue
          </button>
        </div>
      </form>
    </>
  );
};

export default StepBusinessProfile;