import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { RecoilRoot } from "recoil";
import { createUploadLink } from "apollo-upload-client";

import { HomeGrid } from "./views/HomeGrid";
import { Operation } from "./Operation";
import { Search } from "./views/Search";
import { AuthorizeView } from "./views/AuthorizeView";
import { ViewContainer } from "./views/ViewContainer";
import { SquareTestView } from "./SquareTestView";
import { Contact } from "./views/Contact";
import { Cart } from "./views/Cart";

import { IntlProvider } from "react-intl";
import English from "./lang/en.json";
import { Product } from "./views/Product";
import { Marketplace } from "./views/Marketplace";
import { MarketplaceCreateOrEdit } from "./views/MarketplaceCreateOrEdit";
import { AccountContainer } from "./views/Account";
import { Account } from "./account/Account";
import { CompanyInformation } from "./account/CompanyInformation";
import { CompanyVerification } from "./account/CompanyVerification";
import { Signin } from "./views/Signin";
import { Signup } from "./views/Signup";
import Complete from "./signup/Complete";
import { HomeAlt } from "./views/HomeAlt";
import { Terms } from "./views/Terms";
import { NotFound } from "./views/404";
import { ClerkProvider, useAuth } from "@clerk/clerk-react";
import { IsLoggedInWrapper } from "./views/IsLoggedInWrapper";
import { AccountPlans } from "./account/AccountPlans";
import SignupPayment from "./signup/SignupPayment";
import { ForgotPassword } from "./views/ForgotPassword";
import { setupSentry } from "../lib/sentry";

setupSentry();
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const PUBLISHABLE_KEY = process.env.CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const App = () => {
  const { isSignedIn, getToken } = useAuth();
  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: isSignedIn ? `Bearer ${await getToken()}` : "",
      },
    };
  });

  // const httpLink = createHttpLink({ uri: "/graphql" });
  const uploadLink = createUploadLink({ uri: "/graphql" });

  const client = new ApolloClient({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    link: authLink.concat(uploadLink as any),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <SentryRoutes>
          <Route
            path="/"
            element={
              <ViewContainer isHome>
                <HomeAlt />
              </ViewContainer>
            }
          />
          <Route
            path="/home-grid"
            element={
              <ViewContainer>
                <HomeGrid />
              </ViewContainer>
            }
          />
          <Route
            path="/marketplace/create"
            element={
              <ViewContainer>
                <IsLoggedInWrapper>
                  <MarketplaceCreateOrEdit />
                </IsLoggedInWrapper>
              </ViewContainer>
            }
          />
          <Route
            path="/marketplace/edit"
            element={
              <ViewContainer>
                <IsLoggedInWrapper>
                  <MarketplaceCreateOrEdit />
                </IsLoggedInWrapper>
              </ViewContainer>
            }
          />
          <Route
            path="/marketplace"
            element={
              <ViewContainer>
                <IsLoggedInWrapper>
                  <Marketplace />
                </IsLoggedInWrapper>
              </ViewContainer>
            }
          />

          <Route
            path="/marketplace/list/:type?"
            element={
              <ViewContainer>
                <IsLoggedInWrapper>
                  <Marketplace />
                </IsLoggedInWrapper>
              </ViewContainer>
            }
          />
          <Route
            path="/account"
            element={
              <ViewContainer>
                <IsLoggedInWrapper>
                  <AccountContainer>
                    <Account />
                  </AccountContainer>
                </IsLoggedInWrapper>
              </ViewContainer>
            }
          />
          <Route
            path="/account/company-information"
            element={
              <ViewContainer>
                <IsLoggedInWrapper>
                  <AccountContainer>
                    <CompanyInformation />
                  </AccountContainer>
                </IsLoggedInWrapper>
              </ViewContainer>
            }
          />
          <Route
            path="/account/company-verification"
            element={
              <ViewContainer>
                <IsLoggedInWrapper>
                  <AccountContainer>
                    <CompanyVerification />
                  </AccountContainer>
                </IsLoggedInWrapper>
              </ViewContainer>
            }
          />
          <Route
            path="/account/plans"
            element={
              <ViewContainer>
                <IsLoggedInWrapper>
                  <AccountContainer>
                    <AccountPlans />
                  </AccountContainer>
                </IsLoggedInWrapper>
              </ViewContainer>
            }
          />
          <Route
            path="/signin"
            element={
              <ViewContainer>
                <Signin />
              </ViewContainer>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <ViewContainer>
                <ForgotPassword />
              </ViewContainer>
            }
          />
          <Route
            path="/signup"
            element={
              <ViewContainer>
                <Signup />
              </ViewContainer>
            }
          />
          <Route
            path="/signup/business-profile"
            element={
              <ViewContainer>
                <Signup />
              </ViewContainer>
            }
          />
          <Route
            path="/signup/user-profile"
            element={
              <ViewContainer>
                <Signup />
              </ViewContainer>
            }
          />
          <Route
            path="/signup/plans"
            element={
              <ViewContainer>
                <Signup />
              </ViewContainer>
            }
          />
          <Route
            path="/signup/payment"
            element={
              <ViewContainer>
                <SignupPayment />
              </ViewContainer>
            }
          />
          <Route
            path="/signup/verification"
            element={
              <ViewContainer>
                <Signup />
              </ViewContainer>
            }
          />
          <Route
            path="/signup/complete"
            element={
              <ViewContainer>
                <Complete />
              </ViewContainer>
            }
          />
          <Route
            path="/search/:query?/:page?"
            element={
              <ViewContainer>
                <Search />
              </ViewContainer>
            }
          />
          <Route
            path="/operations/:id/edit"
            element={
              <ViewContainer>
                <Operation isEditProfile />
              </ViewContainer>
            }
          />
          <Route
            path="/operations/:id"
            element={
              <ViewContainer>
                <Operation />
              </ViewContainer>
            }
          />
          <Route
            path="/listings/:id"
            element={
              <ViewContainer>
                <Product type="listing" />
              </ViewContainer>
            }
          />
          <Route
            path="/asks/:id"
            element={
              <ViewContainer>
                <Product type="ask" />
              </ViewContainer>
            }
          />
          <Route
            path="/terms"
            element={
              <ViewContainer>
                <Terms />
              </ViewContainer>
            }
          />
          <Route
            path="/authorize"
            element={
              <ViewContainer>
                <AuthorizeView />
              </ViewContainer>
            }
          />
          <Route
            path="/square-test"
            element={
              <ViewContainer>
                <SquareTestView />
              </ViewContainer>
            }
          />
          <Route
            path="/contact"
            element={
              <ViewContainer>
                <Contact />
              </ViewContainer>
            }
          />
          <Route
            path="/cart"
            element={
              <ViewContainer>
                <Cart />
              </ViewContainer>
            }
          />
          <Route
            path="*"
            element={
              <ViewContainer>
                <NotFound />
              </ViewContainer>
            }
          />
        </SentryRoutes>
      </BrowserRouter>
    </ApolloProvider>
  );
};

const ProviderWrappedApp = () => {
  return (
    <RecoilRoot>
      <IntlProvider messages={English} locale="en" defaultLocale="en">
        <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
          <App />
        </ClerkProvider>
      </IntlProvider>
    </RecoilRoot>
  );
};

export default ProviderWrappedApp;
