import React from "react";
import { Link } from "../Link";

function Terms() {
  return (
    <main>
      <div className="container">
        <div className="max-w-[768px] mx-auto py-10 md:py-20">
          <h3 className="font-semibold text-primary-400 mb-4">
            Terms of Service
          </h3>
          <h1 className="font-bold text-4xl text-primary-900 mb-10">Terms</h1>
          <div className="flex flex-col gap-10">
            <p className="text-xl">
              Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem.
              At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at
              vitae feugiat egestas ac. Diam nulla orci at in viverra
              scelerisque eget. Eleifend egestas fringilla sapien.
            </p>
            <p>
              Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus
              enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor
              praesent donec est. Odio penatibus risus viverra tellus varius sit
              neque erat velit. Faucibus commodo massa rhoncus, volutpat.
              Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae
              sed turpis id.
            </p>
            <div className="flex flex-col gap-6">
              <div className="font-semibold flex gap-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-indigo-600 relative top-1"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.857 6.191C11.9149 6.11129 11.9566 6.02095 11.9796 5.92514C12.0026 5.82933 12.0065 5.72994 11.991 5.63262C11.9756 5.5353 11.9412 5.44198 11.8897 5.35797C11.8382 5.27396 11.7707 5.20091 11.691 5.143C11.6113 5.08509 11.5209 5.04344 11.4251 5.02044C11.3293 4.99744 11.2299 4.99354 11.1326 5.00895C11.0353 5.02437 10.942 5.0588 10.858 5.11028C10.774 5.16176 10.7009 5.22929 10.643 5.309L7.16 10.099L5.28 8.219C5.21078 8.1474 5.128 8.0903 5.03647 8.05104C4.94495 8.01178 4.84653 7.99114 4.74694 7.99032C4.64736 7.9895 4.54861 8.00852 4.45646 8.04628C4.3643 8.08403 4.28059 8.13976 4.2102 8.21021C4.13982 8.28066 4.08417 8.36443 4.0465 8.45662C4.00883 8.54881 3.9899 8.64758 3.99081 8.74716C3.99173 8.84674 4.01246 8.94515 4.05181 9.03663C4.09116 9.12812 4.14834 9.21085 4.22 9.28L6.72 11.78C6.79663 11.8567 6.88896 11.9158 6.99065 11.9534C7.09233 11.9909 7.20094 12.006 7.30901 11.9975C7.41708 11.9891 7.52203 11.9573 7.61663 11.9044C7.71123 11.8515 7.79324 11.7787 7.857 11.691L11.857 6.191Z"
                    fill="currentColor"
                  />
                </svg>
                <p className="flex-1">
                  Data types. Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit. Maiores impedit perferendis suscipit eaque,
                  iste dolor cupiditate blanditiis ratione.
                </p>
              </div>
              <div className="font-semibold flex gap-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-indigo-600 relative top-1"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.857 6.191C11.9149 6.11129 11.9566 6.02095 11.9796 5.92514C12.0026 5.82933 12.0065 5.72994 11.991 5.63262C11.9756 5.5353 11.9412 5.44198 11.8897 5.35797C11.8382 5.27396 11.7707 5.20091 11.691 5.143C11.6113 5.08509 11.5209 5.04344 11.4251 5.02044C11.3293 4.99744 11.2299 4.99354 11.1326 5.00895C11.0353 5.02437 10.942 5.0588 10.858 5.11028C10.774 5.16176 10.7009 5.22929 10.643 5.309L7.16 10.099L5.28 8.219C5.21078 8.1474 5.128 8.0903 5.03647 8.05104C4.94495 8.01178 4.84653 7.99114 4.74694 7.99032C4.64736 7.9895 4.54861 8.00852 4.45646 8.04628C4.3643 8.08403 4.28059 8.13976 4.2102 8.21021C4.13982 8.28066 4.08417 8.36443 4.0465 8.45662C4.00883 8.54881 3.9899 8.64758 3.99081 8.74716C3.99173 8.84674 4.01246 8.94515 4.05181 9.03663C4.09116 9.12812 4.14834 9.21085 4.22 9.28L6.72 11.78C6.79663 11.8567 6.88896 11.9158 6.99065 11.9534C7.09233 11.9909 7.20094 12.006 7.30901 11.9975C7.41708 11.9891 7.52203 11.9573 7.61663 11.9044C7.71123 11.8515 7.79324 11.7787 7.857 11.691L11.857 6.191Z"
                    fill="currentColor"
                  />
                </svg>
                <p className="flex-1">
                  Data types. Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit. Maiores impedit perferendis suscipit eaque,
                  iste dolor cupiditate blanditiis ratione.
                </p>
              </div>
              <div className="font-semibold flex gap-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-indigo-600 relative top-1"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.857 6.191C11.9149 6.11129 11.9566 6.02095 11.9796 5.92514C12.0026 5.82933 12.0065 5.72994 11.991 5.63262C11.9756 5.5353 11.9412 5.44198 11.8897 5.35797C11.8382 5.27396 11.7707 5.20091 11.691 5.143C11.6113 5.08509 11.5209 5.04344 11.4251 5.02044C11.3293 4.99744 11.2299 4.99354 11.1326 5.00895C11.0353 5.02437 10.942 5.0588 10.858 5.11028C10.774 5.16176 10.7009 5.22929 10.643 5.309L7.16 10.099L5.28 8.219C5.21078 8.1474 5.128 8.0903 5.03647 8.05104C4.94495 8.01178 4.84653 7.99114 4.74694 7.99032C4.64736 7.9895 4.54861 8.00852 4.45646 8.04628C4.3643 8.08403 4.28059 8.13976 4.2102 8.21021C4.13982 8.28066 4.08417 8.36443 4.0465 8.45662C4.00883 8.54881 3.9899 8.64758 3.99081 8.74716C3.99173 8.84674 4.01246 8.94515 4.05181 9.03663C4.09116 9.12812 4.14834 9.21085 4.22 9.28L6.72 11.78C6.79663 11.8567 6.88896 11.9158 6.99065 11.9534C7.09233 11.9909 7.20094 12.006 7.30901 11.9975C7.41708 11.9891 7.52203 11.9573 7.61663 11.9044C7.71123 11.8515 7.79324 11.7787 7.857 11.691L11.857 6.191Z"
                    fill="currentColor"
                  />
                </svg>
                <p className="flex-1">
                  Data types. Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit. Maiores impedit perferendis suscipit eaque,
                  iste dolor cupiditate blanditiis ratione.
                </p>
              </div>
            </div>
            <p>
              Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis
              odio id et. Id blandit molestie auctor fermentum dignissim. Lacus
              diam tincidunt ac cursus in vel. Mauris varius vulputate et
              ultrices hac adipiscing egestas. Iaculis convallis ac tempor et
              ut. Ac lorem vel integer orci.
            </p>
            <h2 className="text-2xl text-primary-900 mt-10 font-bold">
              From beginner to expert in 3 hours
            </h2>
            <p>
              Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam
              consequat in. Convallis arcu ipsum urna nibh. Pharetra, euismod
              vitae interdum mauris enim, consequat vulputate nibh. Maecenas
              pellentesque id sed tellus mauris, ultrices mauris. Tincidunt enim
              cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis
              ipsum eu a sed convallis diam.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export { Terms };
