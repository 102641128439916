import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Loading } from "../Loading";
import { useProductList } from "../../hooks/useProductList";
import { useCheckout } from "../../hooks/useCheckout";
import { useStripeSubscriptionsQuery } from "../../graphql/generated-types";

const Plans = () => {
  const { plans, loading: stripeProductsLoading } = useProductList();
  const { data: subscriptions } = useStripeSubscriptionsQuery();
  console.log("🚀 ~ Plans ~ subscriptions:", subscriptions);
  const {
    handleClick,
    loading: checkoutLoading,
    error,
  } = useCheckout("/account/plans", "/account/plans");

  if (stripeProductsLoading) {
    return <Loading />;
  }

  return (
    <div>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      <div className="flex flex-col gap-8">
        {[
          { ...plans.starter, planName: "starter" },
          { ...plans.organicFarms, planName: "organicFarms" },
          { ...plans.premium, planName: "premium" },
        ].map((plan, index) => {
          return (
            <DefaultCard
              key={index}
              index={index}
              price={plan.price}
              recurring={plan.recurring}
              name={plan?.name}
              description={plan?.description}
              features={plan?.features}
              subscribe={plan?.subscribeButtonText}
              handleClick={() => handleClick(plan)}
              loading={checkoutLoading}
            />
          );
        })}
      </div>
    </div>
  );
};

const DefaultCard = ({
  index,
  handleClick,
  loading,
  price,
  recurring,
  name,
  description,
  features,
  subscribe = "Subscribe",
}) => {
  return (
    <div className="w-full rounded-3xl ring-1 ring-gray-200 lg:flex lg:max-w-none">
      <div className="p-8 sm:p-10 lg:flex-1 md:min-w-[400px]">
        <h3 className="text-2xl font-semibold tracking-tight text-primary-600">
          {name}
        </h3>
        <p className="mt-6 text-base leading-7">{description}</p>
        <div className="mt-10 flex items-center gap-x-4">
          <h4 className="flex-none text-sm font-semibold leading-6 text-primary-600">
            What's included
          </h4>
          <div className="h-px flex-auto bg-gray-100" />
        </div>
        <ul
          role="list"
          className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6"
        >
          {features?.map((feature) => (
            <li key={feature} className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-green-600"
                aria-hidden="true"
              />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="-mt-2 p-2 lg:mt-0 lg:flex-1">
        <div
          className={classNames(
            "rounded-2xl bg-primary-50 py-10 text-center lg:flex lg:flex-col lg:justify-center lg:py-16 h-full",
            {
              "bg-primary-600 text-white": index === 1,
            },
          )}
        >
          <div className="mx-auto max-w-[256px] px-8">
            <p
              className={classNames(
                "mt-6 flex items-baseline justify-center gap-x-2 font-semibold",
                { "text-primary-600": index !== 1 },
              )}
            >
              <span className="text-5xl tracking-tight font-general">
                {price ? `$${price}` : `Free`}
              </span>

              {recurring ? (
                <span className="leading-6 tracking-wide">/{recurring}</span>
              ) : null}
            </p>
            <button
              disabled={loading}
              onClick={handleClick}
              className="border border-primary-300 hover:bg-gray-50 bg-white rounded-full px-4 py-2 block w-full text-center shadow-sm mt-10 text-primary-700 font-semibold disabled:opacity-40"
            >
              {loading ? "Loading..." : subscribe}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Plans };
