import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { interestsData } from "../../data";
import InputField from "../marketplace/InputField";
import { SubmitHandler, useForm } from "react-hook-form";
import { useUser } from "@clerk/clerk-react";

type UserProfileUpInputs = {
  firstName: string;
  lastName: string;
  interests: string[];
};

const StepUserProfile = () => {
  const { isLoaded: isClerkLoaded, user } = useUser();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserProfileUpInputs>();
  const [loading, setLoading] = useState(false);
  const [clerkError, setClerkError] = useState("");

  const onSubmit: SubmitHandler<UserProfileUpInputs> = async ({
    firstName,
    lastName,
    interests,
  }) => {
    setLoading(true);
    setClerkError("");
    try {
      await user.update({});
      await user.update({
        unsafeMetadata: { interests },
      });
      navigate("/signup/business-profile");
    } catch (err: any) {
      console.warn(JSON.stringify(err, null, 2));
      setClerkError(err.errors[0].message);
    }
    setLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="p-4 md:p-6 max-w-2xl mx-auto"
    >
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="text-xl md:text-2xl">
            <InputField
              label="First name"
              name="firstName"
              register={register("firstName", { required: true })}
              required
            />
          </div>
          <div className="text-xl md:text-2xl">
            <InputField
              label="Last name"
              name="lastName"
              register={register("lastName", { required: true })}
              required
            />
          </div>
        </div>
        <hr className="border-gray-300" />
        <div className="mb-4">
          <h2 className="text-xl text-gray-700 font-medium mb-2">
            Your interests
          </h2>
          <p className="text-base text-gray-500">
            Please choose the areas that best describe how you plan to use
            Market.Organic.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          {interestsData.map((option, index) => (
            <label
              key={option.value}
              className="flex items-center text-base font-medium text-gray-700 cursor-pointer"
            >
              <input
                type="radio"
                name="interests"
                value={option.value}
                className="mr-3 h-5 w-5"
                defaultChecked={false}
                {...register("interests")}
              />
              {option.label}
            </label>
          ))}
        </div>
      </div>
      {clerkError && (
        <p className="text-red-500 mt-4 text-base">{clerkError}</p>
      )}
      <div className="border-t pt-8 mt-8 flex flex-col md:flex-row justify-end items-center gap-4">
        <button
          type="button"
          onClick={() => navigate("/signup")}
          className="w-full md:w-auto rounded-full px-6 py-3 text-center shadow-sm hover:bg-gray-50 border border-primary-300 text-gray-700 font-semibold text-lg"
        >
          Back
        </button>
        <button
          type="submit"
          disabled={loading}
          className="w-full md:w-auto rounded-full px-6 py-3 text-center shadow-sm bg-primary-600 hover:bg-primary-700 text-white font-semibold text-lg disabled:bg-gray-400 disabled:pointer-events-none"
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default StepUserProfile;
