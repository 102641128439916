import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { useSendEmailBusinessVerificationMutation } from "../../graphql/generated-types";

const CompanyVerification = () => {
  return (
    <>
      {/* Removed the Company Verification section */}
      <div>
        <div className="flex flex-col">
          {/* <CompanyCertificate /> */}
          {/* <hr className="my-10" /> */}
          <CompanyOID />
        </div>
      </div>
    </>
  );
};

type FileVerificationInputs = {
  file?: File;
};

const CompanyOID = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const { organization } = useOrganization();

  const [sendEmailBusinessVerification] =
    useSendEmailBusinessVerificationMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FileVerificationInputs>();

  const onSubmit: SubmitHandler<FileVerificationInputs> = async ({ file }) => {
    setLoading(true);

    try {
      await sendEmailBusinessVerification({
        variables: {
          clerkUserId: user.id,
          clerkOrganizationId: organization.id,
          image: acceptedFiles[0],
        },
      });
    } catch (error) {}

    setLoading(false);
  };

  return (
    <>
      <div className="mb-6">
        <h2 className="text-gray-900 font-medium">USDA Organic Verification</h2>
        <p className="text-sm text-gray-500">
          Upload your USDA Organic Certificate below to verify your Certified
          Organic status.
        </p>
      </div>
      <h4 className="text-sm text-gray-700 mb-2">USDA Organic Certificate</h4>

      <div className="p-4 rounded-l-none border-l-4 border-blue-400 rounded-lg mb-6 bg-blue-50 flex items-center justify-between gap-4">
        <div className="flex items-start gap-4">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="fill-blue-400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V14C9 14.5523 9.44772 15 10 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V10C11 9.44772 10.5523 9 10 9H9Z"
            />
          </svg>

          <p className="text-blue-800 font-medium text-sm">
            Your Organic Certificate is under review! You will receive an email
            once it is approved.
          </p>
        </div>
      </div>

      <div className="p-4 rounded-l-none border-l-4 border-green-400 rounded-lg mb-6 bg-green-50 flex items-center justify-between gap-4">
        <div className="flex items-start gap-4">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="fill-green-400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
            />
          </svg>

          <p className="text-blue-800 font-medium text-sm">
            <span className="text-green-900 font-semibold">
              Your Organic Certificate has been approved!{" "}
            </span>
            <span className="text-green-700">
              You can now import your product list and make listings of items
              you want to sell.
            </span>
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="flex flex-col rounded-md border-2 border-dashed border-primary-300 items-center justify-center hover:bg-gray-50 cursor-default">
            <div
              {...getRootProps({
                className:
                  "dropzone p-5 w-full flex flex-col gap-2 item-center justify-center text-center",
              })}
            >
              <input {...getInputProps()} />
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary-400 block mx-auto"
              >
                <path
                  d="M28 8H12C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12V32M8 32V36C8 37.0609 8.42143 38.0783 9.17157 38.8284C9.92172 39.5786 10.9391 40 12 40H36C37.0609 40 38.0783 39.5786 38.8284 38.8284C39.5786 38.0783 40 37.0609 40 36V28M8 32L17.172 22.828C17.9221 22.0781 18.9393 21.6569 20 21.6569C21.0607 21.6569 22.0779 22.0781 22.828 22.828L28 28M40 20V28M40 28L36.828 24.828C36.0779 24.0781 35.0607 23.6569 34 23.6569C32.9393 23.6569 31.9221 24.0781 31.172 24.828L28 28M28 28L32 32M36 8H44M40 4V12M28 16H28.02"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="font-medium text-sm text-primary-600">
                <span className="underline">Upload a file</span> or drag and
                drop
              </div>
              <div className="text-xs text-primary-500">Up to 10MB</div>
            </div>
          </section>

          {acceptedFiles.length ? (
            <>
              <div className="mt-4">
                <h4 className="font-medium">Files:</h4>
                <ul className="list-disc pl-4">
                  {acceptedFiles.map((file) => (
                    <li key={file.name}>{file.name}</li>
                  ))}
                </ul>
              </div>
            </>
          ) : null}

          <div className="flex justify-end mt-4 gap-6">
            <button
              type="submit"
              className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export { CompanyVerification };
