const certifiedOrganicFarms = [
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/300/300",
    type: "Handling",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/305/305",
    type: "Handling",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/402/302",
    type: "Handling",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "",
    type: "Handling",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/500/300",
    type: "Handling",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/350/300",
    type: "Handling",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "",
    type: "Handling",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/300/300",
    type: "Handling",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
];

const certifiedOrganicGoods = [
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/300/300",
    type: "Organic Haas Avocado",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/305/305",
    type: "Walnuts - In Shell",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/402/302",
    type: "Whole Brown Flax Seeds",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/308/308",
    type: "Peruvian Red Globe Grape",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "",
    type: "Organic Haas Avocado",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/350/300",
    type: "Walnuts - In Shell",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "",
    type: "Whole Brown Flax Seeds",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
  {
    name: "DuPage County, Illinois, USA",
    image: "https://picsum.photos/300/300",
    type: "Organic Pears from Mexico",
    author: { image: "", name: "MIT INTERNATIONAL" },
  },
];

const marketplaceListing = [
  {
    name: "CERTIFIED ORGANIC GALA APPLES",
    image: "",
    description: "Organic Goods",
    date: "Created on June 7, 2024",
    status: "pending",
    type: "selling",
  },
  {
    name: "CERTIFIED ORGANC FUJI APPLES",
    image: "",
    description: "Organic Goods",
    date: "Created on May 12, 2024",
    status: "pending",
    type: "selling",
  },
  {
    name: "DJI Drone Monitoring Daily",
    image: "",
    description: "Services",
    date: "Created on May 3, 2024",
    status: "active",
    type: "selling",
  },
  {
    name: "Drone Field Mapping",
    image: "",
    description: "Services",
    date: "Created on January 1, 2024",
    status: "expired",
    type: "selling",
  },
  {
    name: "Organic Fresh Walnuts",
    image: "",
    description: "Organic Goods",
    date: "Created on June 7, 2024",
    status: "active",
    type: "buying",
  },
  {
    name: "Organic Pistachios",
    image: "",
    description: "Organic Goods",
    date: "Created on May 12, 2024",
    status: "active",
    type: "buying",
  },
];

const countries = [
  { name: "United States", value: "US" },
  { name: "Mexico", value: "MX" },
  { name: "Afghanistan", value: "AF" },
  { name: "Albania", value: "AL" },
  { name: "Algeria", value: "DZ" },
  { name: "Andorra", value: "AD" },
  { name: "Angola", value: "AO" },
  { name: "Antigua and Barbuda", value: "AG" },
  { name: "Argentina", value: "AR" },
  { name: "Armenia", value: "AM" },
  { name: "Australia", value: "AU" },
  { name: "Austria", value: "AT" },
  { name: "Azerbaijan", value: "AZ" },
  { name: "Bahamas", value: "BS" },
  { name: "Bahrain", value: "BH" },
  { name: "Bangladesh", value: "BD" },
  { name: "Barbados", value: "BB" },
  { name: "Belarus", value: "BY" },
  { name: "Belgium", value: "BE" },
  { name: "Belize", value: "BZ" },
  { name: "Benin", value: "BJ" },
  { name: "Bhutan", value: "BT" },
  { name: "Bolivia", value: "BO" },
  { name: "Bosnia and Herzegovina", value: "BA" },
  { name: "Botswana", value: "BW" },
  { name: "Brazil", value: "BR" },
  { name: "Brunei", value: "BN" },
  { name: "Bulgaria", value: "BG" },
  { name: "Burkina Faso", value: "BF" },
  { name: "Burundi", value: "BI" },
  { name: "Cambodia", value: "KH" },
  { name: "Cameroon", value: "CM" },
  { name: "Canada", value: "CA" },
  { name: "Cape Verde", value: "CV" },
  { name: "Central African Republic", value: "CF" },
  { name: "Chad", value: "TD" },
  { name: "Chile", value: "CL" },
  { name: "China", value: "CN" },
  { name: "Colombia", value: "CO" },
  { name: "Comoros", value: "KM" },
  { name: "Congo", value: "CG" },
  { name: "Costa Rica", value: "CR" },
  { name: "Croatia", value: "HR" },
  { name: "Cuba", value: "CU" },
  { name: "Cyprus", value: "CY" },
  { name: "Czech Republic", value: "CZ" },
  { name: "Democratic Republic of the Congo", value: "CD" },
  { name: "Denmark", value: "DK" },
  { name: "Djibouti", value: "DJ" },
  { name: "Dominica", value: "DM" },
  { name: "Dominican Republic", value: "DO" },
  { name: "East Timor", value: "TL" },
  { name: "Ecuador", value: "EC" },
  { name: "Egypt", value: "EG" },
  { name: "El Salvador", value: "SV" },
  { name: "Equatorial Guinea", value: "GQ" },
  { name: "Eritrea", value: "ER" },
  { name: "Estonia", value: "EE" },
  { name: "Ethiopia", value: "ET" },
  { name: "Fiji", value: "FJ" },
  { name: "Finland", value: "FI" },
  { name: "France", value: "FR" },
  { name: "Gabon", value: "GA" },
  { name: "Gambia", value: "GM" },
  { name: "Georgia", value: "GE" },
  { name: "Germany", value: "DE" },
  { name: "Ghana", value: "GH" },
  { name: "Greece", value: "GR" },
  { name: "Grenada", value: "GD" },
  { name: "Guatemala", value: "GT" },
  { name: "Guinea", value: "GN" },
  { name: "Guinea-Bissau", value: "GW" },
  { name: "Guyana", value: "GY" },
  { name: "Haiti", value: "HT" },
  { name: "Honduras", value: "HN" },
  { name: "Hungary", value: "HU" },
  { name: "Iceland", value: "IS" },
  { name: "India", value: "IN" },
  { name: "Indonesia", value: "ID" },
  { name: "Iran", value: "IR" },
  { name: "Iraq", value: "IQ" },
  { name: "Ireland", value: "IE" },
  { name: "Israel", value: "IL" },
  { name: "Italy", value: "IT" },
  { name: "Ivory Coast", value: "CI" },
  { name: "Jamaica", value: "JM" },
  { name: "Japan", value: "JP" },
  { name: "Jordan", value: "JO" },
  { name: "Kazakhstan", value: "KZ" },
  { name: "Kenya", value: "KE" },
  { name: "Kiribati", value: "KI" },
  { name: "Kosovo", value: "XK" },
  { name: "Kuwait", value: "KW" },
  { name: "Kyrgyzstan", value: "KG" },
  { name: "Laos", value: "LA" },
  { name: "Latvia", value: "LV" },
  { name: "Lebanon", value: "LB" },
  { name: "Lesotho", value: "LS" },
  { name: "Liberia", value: "LR" },
  { name: "Libya", value: "LY" },
  { name: "Liechtenstein", value: "LI" },
  { name: "Lithuania", value: "LT" },
  { name: "Luxembourg", value: "LU" },
  { name: "Madagascar", value: "MG" },
  { name: "Malawi", value: "MW" },
  { name: "Malaysia", value: "MY" },
  { name: "Maldives", value: "MV" },
  { name: "Mali", value: "ML" },
  { name: "Malta", value: "MT" },
  { name: "Marshall Islands", value: "MH" },
  { name: "Mauritania", value: "MR" },
  { name: "Mauritius", value: "MU" },
  { name: "Mexico", value: "MX" },
  { name: "Micronesia", value: "FM" },
  { name: "Moldova", value: "MD" },
  { name: "Monaco", value: "MC" },
  { name: "Mongolia", value: "MN" },
  { name: "Montenegro", value: "ME" },
  { name: "Morocco", value: "MA" },
  { name: "Mozambique", value: "MZ" },
  { name: "Myanmar", value: "MM" },
  { name: "Namibia", value: "NA" },
  { name: "Nauru", value: "NR" },
  { name: "Nepal", value: "NP" },
  { name: "Netherlands", value: "NL" },
  { name: "New Zealand", value: "NZ" },
  { name: "Nicaragua", value: "NI" },
  { name: "Niger", value: "NE" },
  { name: "Nigeria", value: "NG" },
  { name: "North Korea", value: "KP" },
  { name: "North Macedonia", value: "MK" },
  { name: "Norway", value: "NO" },
  { name: "Oman", value: "OM" },
  { name: "Pakistan", value: "PK" },
  { name: "Palau", value: "PW" },
  { name: "Palestine", value: "PS" },
  { name: "Panama", value: "PA" },
  { name: "Papua New Guinea", value: "PG" },
  { name: "Paraguay", value: "PY" },
  { name: "Peru", value: "PE" },
  { name: "Philippines", value: "PH" },
  { name: "Poland", value: "PL" },
  { name: "Portugal", value: "PT" },
  { name: "Qatar", value: "QA" },
  { name: "Romania", value: "RO" },
  { name: "Russia", value: "RU" },
  { name: "Rwanda", value: "RW" },
  { name: "Saint Kitts and Nevis", value: "KN" },
  { name: "Saint Lucia", value: "LC" },
  { name: "Saint Vincent and the Grenadines", value: "VC" },
  { name: "Samoa", value: "WS" },
  { name: "San Marino", value: "SM" },
  { name: "Sao Tome and Principe", value: "ST" },
  { name: "Saudi Arabia", value: "SA" },
  { name: "Senegal", value: "SN" },
  { name: "Serbia", value: "RS" },
  { name: "Seychelles", value: "SC" },
  { name: "Sierra Leone", value: "SL" },
  { name: "Singapore", value: "SG" },
  { name: "Slovakia", value: "SK" },
  { name: "Slovenia", value: "SI" },
  { name: "Solomon Islands", value: "SB" },
  { name: "Somalia", value: "SO" },
  { name: "South Africa", value: "ZA" },
  { name: "South Korea", value: "KR" },
  { name: "South Sudan", value: "SS" },
  { name: "Spain", value: "ES" },
  { name: "Sri Lanka", value: "LK" },
  { name: "Sudan", value: "SD" },
  { name: "Suriname", value: "SR" },
  { name: "Sweden", value: "SE" },
  { name: "Switzerland", value: "CH" },
  { name: "Syria", value: "SY" },
  { name: "Taiwan", value: "TW" },
  { name: "Tajikistan", value: "TJ" },
  { name: "Tanzania", value: "TZ" },
  { name: "Thailand", value: "TH" },
  { name: "Togo", value: "TG" },
  { name: "Tonga", value: "TO" },
  { name: "Trinidad and Tobago", value: "TT" },
  { name: "Tunisia", value: "TN" },
  { name: "Turkey", value: "TR" },
  { name: "Turkmenistan", value: "TM" },
  { name: "Tuvalu", value: "TV" },
  { name: "Uganda", value: "UG" },
  { name: "Ukraine", value: "UA" },
  { name: "United Arab Emirates", value: "AE" },
  { name: "United Kingdom", value: "GB" },
  { name: "United States", value: "US" },
  { name: "Uruguay", value: "UY" },
  { name: "Uzbekistan", value: "UZ" },
  { name: "Vanuatu", value: "VU" },
  { name: "Vatican City", value: "VA" },
  { name: "Venezuela", value: "VE" },
  { name: "Vietnam", value: "VN" },
  { name: "Yemen", value: "YE" },
  { name: "Zambia", value: "ZM" },
  { name: "Zimbabwe", value: "ZW" },
];

const businessTypesData = [
  "Farm",
  "Processor",
  "Distributor/Wholesale",
  "Manufacturer/Co-Packer",
  "Consumer Goods Brand",
  "Retailer",
  "Restaurant",
  "Broker/Trader",
  "Academic",
  "Buying Club",
  "Consulting",
  "Government",
  "Hospitality",
  "Individual",
  "Logistics",
  "Market Research",
  "Sole Proprietorship",
  "Technology",
];

const interestsData = [
  {
    label:
      "Sourcing and purchasing the best quality Certified Organic seeds, crops, and products",
    value: "sourcing-purcharsing",
  },
  {
    label:
      "Selling and marketing my Certified Organic farm or company's products",
    value: "selling-marketing",
  },
  {
    label:
      "Marketing my company's services to Certified Organic farms and businesses",
    value: "marketing",
  },
  {
    label: "Finding or selling Certified Organic contracts",
    value: "finding-selling",
  },
  { label: "Conducting research in agriculture", value: "research" },
  { label: "I am affiliated with a retailer", value: "affiliated" },
  { label: "Other", value: "other" },
];

export type Plan = {
  name: string;
  price?: number;
  recurring?: string;
  priceId?: string;
  description: string;
  features: string[];
  subscribeButtonText: string;
};

export type PlanData = {
  starter: Plan;
  organicFarms: Plan;
  premium: Plan;
};

const planData: PlanData = {
  starter: {
    name: "Starter plan",
    description: "A basic plan that allows up to 2 listings per month.",
    features: [
      "Custom profile for finding Certified Organic goods",
      "Access to the best search engine for Certified Organic",
      "Unlimited buyer/seller contact",
      "Up to 2 listings per month",
    ],
    subscribeButtonText: "Get Started For Free",
  },
  organicFarms: {
    name: "Certified Organic Farms",
    description:
      "A plan that allows you to list your Certified Organic farm on the marketplace.",
    features: [
      "Everything in the Starter Plan",
      "Unlimited listings",
      "Ability to list Organic goods for sale",
      "Verified Certified Organic profiles",
      "Exclusive for Certified Organic farms",
    ],
    subscribeButtonText: "Get Started For Free",
  },
  premium: {
    name: "Premium Plan",
    description:
      "A premium plan that includes unlimited listings for any type of business, premium placements for listings, and priority customer support.",
    features: [
      "Everything in the Starter Plan",
      "Unlimited listings",
      "Premium spotlight placements for your listings",
      "Verified business profiles",
      "Priority customer support",
    ],
    subscribeButtonText: "Subscribe",
  },
};

export const listingTypeData = [
  {
    slug: "selling",
    label: "I want to sell something",
    description: "List Certified Organic goods or services you want to sell",
  },
  {
    slug: "buying",
    label: "I want to buy something",
    description: "List Certified Organic goods or services you want to buy",
  },
];

export {
  certifiedOrganicFarms,
  certifiedOrganicGoods,
  marketplaceListing,
  countries,
  businessTypesData,
  interestsData,
  planData,
};
